import * as _ from 'lodash'

enum EnumFeature {
  'DUMMY',
  'BAT-XX1-FOR_TEST_FUNC-F',
  'BAT-XX2-FOR_TEST_FUNC-F',
  'GW-656-ONEDAY-F',
  'DWB-5-REVOLVING-LOAN-F',
  'DWB-107-BTL-AUTO-ONE-F',
  'DWB-227-SPACIAL-RATE-F',
  'DWB-123-SMILE-BASE-F',
  'DWB-793-SUSCO-CAMPAIGN-F',
  'DWB-336-AUTO-DEBIT-CAMPAIGN-F',
  'DWB-727-DDO-LIFTTIME-THEONE',
  'DWB-396-BTL-GPF-2W-2022-Q4-F',
  'DWB-400-BTL-CENTRAL-EMPLOYEE-DEAL-2022-Q4-4W-F',
  'DWB-393-KEYCHAIN-CAMPAIGN-F',
  'DWB-428-CUSTOMER-FLOOD-F',
  'DWB-425-DDO-THEONE-LINE',
  'DWB-423-PROMPTCHAI-F',
  'DWB-531-EXTEND-MEMBER-GET-MEMBET-2023-F',
  'DWB-729-DDO-RETENTION-CAMPAIGN-F',
  'DWB-418-CAMPAIGN-DEALER-REFER-F',
  'DWB-432-DDO-RETENTION-CAMPAIGN-FOR-LINE-F',
  'SPACIAL-DAY-PAGE-F',
  'DWB-475-BTL-CAMPAIGN-2W-SHOPEEFOOD-23Q1-F',
  'DWB-522-APPLY-CAR-LOAN-GET-PREMIUM-TUMBLER-F',
  'DWB-744-BTL-CENTRAL-EMPLOYEE-DEAL-2W-F',
  'DWB-743-BTL-CENTRAL-EMPLOYEE-DEAL-4W-F',
  'DWB-638-UPDATE-FOOTER-F',
  'DWB-670-SEPLAT-DROPLEAD-FROM-CAMPAIGN-F',
  'DWB-712-APPLY-CAR-LOAN-GET-USB-CABLE-F',
  'DWB-779-UPDATE-ADDRESS-CONTACT-F',
  'DWB-769-BTL-C4C-PRIME-4W-F',
  'DWB-830-20-YEARS-CAMPAIGN-Q3-F',
  'DWB-1033-BTL-C4C-KA-THE1-F',
  'DWB-819-BIGBIKE-2023-Q3-F',
  'DWB-846-CENTRAL-EMPLOYEE-DEAL-4W-2023Q4-F',
  'DWB-847-CENTRAL-EMPLOYEE-DEAL-2W-2023Q4-F',
  'DWB-939-20-YEARS-CAMPAIGN-Q4-F',
  'DWB-940-UPDATE-ADDRESS-KRABI-F',
  'DWB-944-CHANGE-START-DATE-BOTTLE-F',
  'DWB-960-TRUE-TWENTY-YEARS-CAMPAIGN-Q4-F',
  'DWB-XXX-REVAMP-2023-Q4-F',
  'DWB-984-BTL-C4C-DEALER-REFER-MC-F',
  'DWB-1162-BTL-CENTRAL-EMPLOYEE-DEAL-2W4W-F',
  'DWB-1064-WELCOME-BACK-FOREVER-CAMPAIGN-F',
  'DWB-918-REVOLVINMG-LOAN-VEHICLE-SERVICE-F',
  'DWB-1023-MODAL-RH-F',
  'DWB-1088-BTL-SUSCO-CAMPAIGN-JAN-NOV-2024-F',
  'DWB-1067-SPIKE-CAMERA-SCAN-QR-F',
  'DWB-969-SUBMIT-REFERRAL-MGM-F',
  'DWB-1166-NECESSARY-F',
  'DWB-1132-FOOD-PANDA-F',
  'DWB-1143-LALAMOVE-F',
  'DWB-1164-GPF-4W-2024Q2-F',
  'DWB-1164-GPF-2W-2024Q2-F',
  'DWB-1164-GPF-REVOLVING-LOAN-2024Q2-F',
  'DWB-1216-CHECKQR-CODE-READ-F',
  'DWB-1588-BTL-BAY-PAYROLL-F',
  'DWB-1237-UPDATGE-INTEREST-RATE-F',
  'DWB-1315-BUTTON-LEAD-FORM-F',
  'DWB-1330-GIVEAWAY-2024Q3-F',
  'DWB-1485-C4C-20-YEARS-F',
  'DWB-1391-UPDATE-FAQ-F',
  'DWB-1510-BTL-NORMAL-PLUS-24Q4-F',
  'DWB-1395-BTL-B-QUIK-F',
  'DWB-1367-BTL-WELCOMEBACK-AB-F',
  'DWB-1438-APPEND-CALCULATOR-BUTTON-F',
  'DWB-1368-PROCHAMBER-F',
  'DWB-1456-BTL-C4C-PAY-IN-TIME-F',
  'DWB-1463-TANGLAK90DAYS-F',
  'DWB-1447-KA-X-TRUE-F',
  'DWB-1448-KA-X-TRUE-RIDER-F',
  'DWB-1509-THREE-THREE-THREE-F',
  'DWB-1475-KMA-ONE-RETAIL-X-DDO-F',
  'DWB-1451-UPDATE-ADDRESS-PHITSANULOK-F',
  'DWB-1494-MEMBER-GET-MEMBER-F',
  'DWB-1575-DIRECT-DETBIT-F'
}

export type Feature = keyof typeof EnumFeature

type CallBacks = { ifOn: () => any; ifOff: () => any }
export const featureToggle = (features: string[] = []) => <T>(
  feature: Feature,
  callbacks?: CallBacks
): T | boolean => {
  const enabled = features.includes(feature)

  if (EnumFeature[feature] === undefined) {
    const error = 'Not found Toggle feature ' + feature
    throw new Error(error)
  }

  if (callbacks !== undefined) {
    return enabled ? callbacks.ifOn() : callbacks.ifOff()
  } else {
    return enabled
  }
}

export type FeatureToggle = ReturnType<typeof featureToggle>

export const mergeFeatures = async (query, app) => {
  const queryFeature = name =>
    app.$env.ALLOW_OVERRIDE_ENABLED_FEATURES === 'true'
      ? values(query[name])
      : []

  const basedFeatures = await fetchBasedFeatures(app)
  const queryOnFeatures = queryFeature('__features_on__')
  const queryOffFeatures = queryFeature('__features_off__')

  return _.difference(
    _.union(basedFeatures, queryOnFeatures),
    queryOffFeatures
  ).sort()
}

const fetchBasedFeatures = app => {
  const envFeatures = values(app.$env.ENABLED_FEATURES)
  return envFeatures
}

const values: (v: string) => string[] = v => (v ? v.split(',') : [])

export default async ({ query, app }, inject) => {
  const features = await mergeFeatures(query, app)
  inject('features', featureToggle(features))
}
